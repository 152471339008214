import React, { createContext, Component } from "react";

import translate from "../content/translate.json";

export const LanguageContext = createContext("en");

class LanguageProvider extends Component {

  constructor(props) {
    super(props);
    const regions = [
      "Jeddah", "Riyadh", "Dammam", "Morocco"
    ];
    const languages = {
      "Morocco": [
        "ar", "fr"
      ]
    };


    const splitURL = window.location.pathname.split("/");
    let region = "";
    splitURL.some(r => {if (regions.includes(r)) region = r})
    // const language_1 = languages[splitURL]
    this.lang1 = languages[region] ? languages[region][0] : "ar";
    this.lang2 = languages[region] ? languages[region][1] : "en";
    this.translate = translate;
    let lang = !splitURL.includes(this.lang2) ? this.lang1 : this.lang2;
    console.log("region", region, this.lang1, this.lang2, lang);
    let dir = lang === "ar" ? "rtl" : "";

    if(splitURL.includes(this.lang1) ||  (splitURL.includes(this.lang2) && splitURL.indexOf(this.lang2) !== 2)) {
      let oldLang = splitURL.includes(this.lang1) ? this.lang1 : this.lang2;
      let newUrl = window.location.pathname.split(oldLang)
          .map(link => {
            link = link.split("/").join("");
            return link ? link : "";
          }).filter(Boolean);
      newUrl += oldLang === this.lang2 ? `/${this.lang2}` : "";
      window.location.href = "/" + newUrl + window.location.search;
    }

    this.html = document.querySelector("html");
    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);

    this.state = {
      lang,
      translate: translate[lang],
      dir
    };

    window.lang = lang;
  }

  checkLang() {
    const splitURL = window.location.pathname.split("/");
    let lang = !splitURL.includes(this.lang2) ? this.lang1 : this.lang2;
    const dir = lang === "ar" ? "rtl" : "";
    if (this.state.lang !== lang) {
      const translate = this.translate[lang];
      this.setState({ lang, translate, dir });
    }
  }

  toggleLang = lang => {
    window.lang = lang;
    const splitLang = lang === this.lang1 ?  this.lang2 : "/";

    const splitURL = window.location.pathname.split("/");
    if (splitURL.includes(lang)) return false;

    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);

    let newUrl = window.location.pathname.split(splitLang)
        .map(link => {
          link = link.split("/").join("");
          return link ? link : "";
        }).filter(Boolean);

    newUrl = `/${newUrl[0]}${lang === this.lang2 ? `/${this.lang2}` : ""}`;

    window.history.replaceState("", "", newUrl);

    let dir = lang === "ar" ? "rtl" : "";

    const translate = this.translate[lang];
    this.setState({ lang, translate, dir });
  };

  getTranslate = (key) => {
    let { translate } = this.state;
    if (key === undefined) return "";
    if (typeof key === "boolean") return key;
    const keys = key.split(".");
    const keysLength = keys.length;
    if (keysLength < 1) return key;
    for (let index = 0; index < keysLength; index++) {
      if (!translate?.[keys[index]]) return key;
      translate = translate[keys[index]];
    }
    return translate;
  };

  render() {
    return (
        <LanguageContext.Provider value={{
          ...this.state,
          toggleLang: this.toggleLang.bind(this),
          getTranslate: this.getTranslate.bind(this),
          checkLang: this.checkLang.bind(this)
        }}>
          {this.props.children}
        </LanguageContext.Provider>
    )
  }
}

export default LanguageProvider;
