import React from 'react';
import Image from "../../components/UI/Images/Image";
import "./thankyou.scss";
import { LanguageContext } from "../../contexts/LanguageContext";
import TagManager from "react-gtm-module";

class ThankYou extends React.Component {

    constructor(props) {
        super(props);
        this.tagManagerArgs = {
            gtmId: 'GTM-PQPZJZX'
        };
        this.state = {
            time: 5
        }

        this.region = this.props.match.params.region;
        this.lang = LanguageContext._currentValue.lang;
        this.location = window.location.href.split("?")[0];
    }

    findGetParameter(parameterName) {
        let result = null,
            tmp = [];
        let items = window.location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }


    componentDidMount() {
        // TagManager.initialize(this.tagManagerArgs);
        this.timer();
        // const noscript = document.createElement("noscript");
        // const country = ["Jeddah", "Dammam", "Riyadh"].includes(this.region) ? "Saudi Arabia" : this.region;
        // noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gquothn;cat=hyund0;u1=${this.location};u2=${this.lang};u3=${country};u4=${this.region};u5=Elantra;u6=${this.findGetParameter("bank")};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
        //
        // window.gtag('event', 'conversion', {
        //     'allow_custom_scripts': true,
        //     'u1': this.location,
        //     'u2': this.lang,
        //     'u3': country,
        //     'u4': this.region,
        //     'u5': 'Elantra',
        //     'u6': this.findGetParameter("bank"),
        //     'send_to': 'DC-3220295/gquothn/hyund0+unique'
        // });
        // document.querySelector("body").appendChild(noscript);
    }

  timer() {
    setInterval(() => {
      if (this.state.time > 0) {
        this.setState({ time: this.state.time - 1 })
      }
      if (this.state.time <= 1) {
        window.history.go(-1);
      }
    }, 1000);
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {context => {
          return (
            <main>
              <section className={"pp-section-thankyou"}>
                <div>
                  <Image src={"svg/logo.svg"}
                         alt={"Hyundai"}
                         className={"logo"} />
                  <h1>
                    {context.getTranslate("thanks.title")}
                  </h1>
                  <h2 className={"subtitle"}>
                    {context.getTranslate("thanks.subtitle")}
                  </h2>
                  <span className={"timer"}>{this.state.time}</span>
                  <a onClick={() => {window.history.go(-1)}}>{context.getTranslate("thanks.cancel")}</a>
                </div>
              </section>
            </main>
          )
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default ThankYou;
