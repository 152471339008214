import React from 'react';
import Parser from "html-react-parser";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, { Navigation, Pagination } from 'swiper';

import Image from "../UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

import 'swiper/swiper.scss'
import './tiles.scss';

SwiperCore.use([Navigation, Pagination]);

export const Tiles = ({slides, bannerAsSlide}) => {

  const [breakPoint, setBreakPoint] = React.useState(3);

  React.useEffect( () => {
    if (window.innerWidth < 1024) {
      setBreakPoint(1)
    } else {
      setBreakPoint(3)
    }
  })

  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <div className={"fs-tiles"}>
            <Swiper slidesPerView={window.innerWidth < 1024 ? 1 : 3}
                    pagination
                    spaceBetween={24}>

              {bannerAsSlide && window.innerWidth < 1024 && <SwiperSlide className={"bannerAsSlide"}>
                <div className={"cover"}>
                  <div className={"dark-cover"}></div>
                  <div className={"label-tiles"}>{Parser(context.getTranslate(bannerAsSlide.label))}</div>
                  <Image src={bannerAsSlide.banner_min}/>
                </div>
                <div className={"title"}>{Parser(context.getTranslate(bannerAsSlide.title))}</div>
              </SwiperSlide>}

              {slides.map(slide => <SwiperSlide>
                <div className={"slide_wrapper"}>
                  <Image src={slide.image}/>
                  <div className={"title"}>{Parser(context.getTranslate(slide.title))}</div>
                </div>
                </SwiperSlide>
              )}

            </Swiper>

          </div>
        );
      }}
    </LanguageContext.Consumer>
  )
};
