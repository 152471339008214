import React from 'react';
import Parser from "html-react-parser";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller, Navigation, Pagination} from 'swiper';

import Image from "../UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

import 'swiper/swiper.scss'
import './tileBlock.scss';
import TitleBlock from "../UI/TitleBlock/TitleBlock";

SwiperCore.use([Navigation, Pagination, Controller]);

export const TileBlock = ({slides, title, className, dir}) => {

  const [breakPoint, setBreakPoint] = React.useState(3);
  const [dire, setDire] = React.useState(dir);

  const check = React.useRef()

  const [controlledSwiper, setControlledSwiper] = React.useState(null);

  React.useEffect( () => {
    if (window.innerWidth < 1024) {
      setBreakPoint(1)
    } else {
      setBreakPoint(3)
    }

  })

  const params = {
    onInit: (swiper) => {
      this.swiper = swiper
    }
  }

  React.useEffect( () => {
    setDire(dir)

    check.current.swiper.rtl = dir == "rtl"
    setControlledSwiper(params)
    console.log("lang change")
  }, [dir])

  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <div className={`block-tiles ${dir}`}>
            {title && <TitleBlock className={"blue"} title={title}/>}
            <Swiper ref={check} slidesPerView={window.innerWidth < 1024 ? 1 : 3}
                    pagination
                    controller={{ control: controlledSwiper }}
                    spaceBetween={24}>
              {window.innerWidth > 1024
                ? slides.map(slide => <div className={"tile"}>
                  <Image src={slide.image_min ? slide.image_min : slide.image}/>
                  <div className={"title"}>{context.getTranslate(slide.title)}</div>
                  <div className={"subtitle"}>{context.getTranslate(slide.subtitle)}</div>
                </div>)
                :
                slides.map((slide, index) => <SwiperSlide className={`${className}`}>
                  <div className={"slide_wrapper"}>
                    <Image src={slide.image_min ? slide.image_min : slide.image}/>
                    <div className={"title"}>{context.getTranslate(slide.title)}</div>
                    {slide.subtitle && <div className={"subtitle"}>{context.getTranslate(slide.subtitle)}</div>}
                  </div>
                </SwiperSlide>
              )}

            </Swiper>

          </div>
        );
      }}
    </LanguageContext.Consumer>
  )
};
