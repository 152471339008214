import React from "react";
import "js-cloudimage-360-view";
import ParametersSlider from "./ParametersSlider";
import "./configurator.scss";
import Toggle from "../Toggle/Toggle";

class Configurator extends React.Component {

    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
        const parameters = props.data["ex"].parameters;

        this.state = {
            activeType: "ex",
            parameters,
            paramData: props.data,
            activeParameters: {},
            contentHeight: "auto",
            folderLink: this.getFolderLink(this.getActiveParameters(parameters))
        }
    }

    componentDidMount() {
        // window.addEventListener("resize", this.onResize.bind(this));
        // this.onResize();
        setTimeout(() => {
            window.CI360.init()
        }, 50);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.reinit();
    }

    getActiveParameters(parameters){
        const activeParams = {};
        Object.keys(parameters).map(key => {
            parameters[key]["slides"].map(parameter => {
                if (parameter.isActive) {
                    activeParams[key] = parameter.value;
                }
                return parameter;
            });
        })
        return activeParams;
    }

    getFolderLink(parameters) {
        if (parameters) {
            let folderLink = `${process.env.PUBLIC_URL + "/assets/images"}/360/${window.innerWidth < 768 ? "Mo" : "pc"}/ex/`;
            folderLink += `${parameters[Object.keys(parameters)[0]] || ""}/`;
            folderLink += `${parameters[Object.keys(parameters)[1]] || ""}/`;

            return folderLink;
        } else {
            return '';
        }
    }

    onResize(){
        this.setState({contentHeight: this.contentRef.current?.offsetWidth * 0.38 + "px"})
    }

    reinit() {
        window.CI360.destroy();
        const {activeParameters, folderLink} = this.state;

        this.contentRef.current.innerHTML = `<div class="cloudimage-360"
                         data-folder="${folderLink}"
                         data-lazyload
                         data-logo-src="${process.env.PUBLIC_URL + "/assets/images/icons/start.png"}"
                         data-filename="${activeParameters[Object.keys(activeParameters)[1]]}_{index}.png"
                         data-amount="35">
                    </div>`;

        window.CI360.init();
    }


    onParameterChange(name, value) {
        window.CI360.destroy();
        let {parameters, activeParameters} = this.state;

        activeParameters[name] = value;

        const folderLink = this.getFolderLink(activeParameters);

        parameters[name]["slides"].map(parameter => {
            parameter.isActive = parameter.value === value;
            return parameter;
        });

        this.setState({parameters, activeParameters, folderLink});

    }

    render() {
        const {parameters} = this.state;
        return (
            <div className={"configurator configurator-360"}>
                <div className={"main-content"}
                     // style={{"height":this.state.contentHeight}}
                     ref={this.contentRef}/>
                <Toggle options={this.props.options}
                        callback={this.props.onToggle}/>
                <div className={"parameters"}>
                    {
                        Object.keys(parameters)
                            .map(key => <ParametersSlider slides={parameters[key].slides}
                                                          name={key}
                                                          title={parameters[key].title}
                                                          callback={this.onParameterChange.bind(this)}/>)
                    }
                </div>
            </div>
        )
    }
}

export default Configurator;
