import React from 'react';
import PropTypes from "prop-types";
import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import "./Hero.scss";

const Hero = ({region, dir, useYoutube}) => {

    const images = [
        {
            "src": `desktop/hero/${region.toLowerCase()}-2x.jpg`,
            "media": "1920"
        },
        {
            "src": `mobile/hero/${region.toLowerCase()}-2x.jpg`,
            "media": "768px"
        },
    ];

    return (
        <section className={"pp-section-hero"} dir={dir}>
            <Image src={images} useLazy={false}/>
            <div className={"container"}>
                <TitleBlock isMain={true}
                            className={"white"}
                            title={"hero.subtitle"}
                            subtitle={"hero.title"}
                            useYoutube={useYoutube}/>
            </div>
        </section>
    );

};

Hero.propTypes = {
    region: PropTypes.string.isRequired,
    dir: PropTypes.string,
};

export default Hero;
