import React from 'react';
import Image from "../../components/UI/Images/Image";
import "./Footer.scss";
import context from "react-router/modules/RouterContext";
import {LanguageContext} from "../../contexts/LanguageContext";

const Footer = ({region}) => {

    const dillers = {
        Dammam: "desktop/dealer/Dammam.svg",
        Jeddah: "footer.jeddahImage",
        Riyadh: "desktop/dealer/Riyadh.png",
        Egypt: "footer.egyptDealerImage",
        Kuwait: "footer.kuwaitDealerImage",
        Bahrain: "desktop/dealer/Bahrain.svg",
        UAE:"desktop/dealer/UAE.png"
    };

    return (
        <LanguageContext.Consumer>
            {context => {return (
        <footer className={"pp-section"}>
            <div className={"container"}>
                <div className={"logos"}>
                    <div className={"dealer"}>
                        <Image useLazy={false} className={`rivenditore ${region.toLowerCase()}`} src={context.getTranslate(dillers[region])} alt={"dealer"}/>
                    </div>
                    <Image className={"logo"} src={"svg/logo-w.svg"} useLazy={false} alt={"Hyundai"}/>
                </div>
                <p className={"text"}>
                    All specifications and descriptions provided herein may
                    be different from the actual specifications and
                    descriptions for the product.
                    Hyundai is a registered trademark of Hyundai Motor Company.
                    All rights reserved, Hyundai Motor Middle East &&nbsp;Africa.
                </p>
            </div>
        </footer>
    )  }}
        </LanguageContext.Consumer>
    );

};

export default Footer;
