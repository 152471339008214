import React from 'react';
import { LanguageContext } from "../../contexts/LanguageContext";
import "./ToggleLang.scss";

const ToggleLang = () => {

  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <ul className={`toggle-lang ${context.lang === "ar" ? "active" : ""}`}>
            <li onClick={() => context.toggleLang("en")} className={context.lang === "en" ? "active" : ""}>EN</li>
            <li onClick={() => context.toggleLang("ar")} className={context.lang === "ar" ? "active" : ""}>AR</li>
          </ul>
        );

      }}
    </LanguageContext.Consumer>
  )

};

ToggleLang.propTypes = {};

export default ToggleLang;
