import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../../UI/Images/Image";
import TextBlock from "../../../UI/TextBlock/TextBlock";
import "./SimpleSlide.scss";

const SimpleSlide = ({ className, slideInfo }) => {

  return (
    <div className={`simple-slide swiper-slide ${className} ${slideInfo.className || ''}`} style={{ ...slideInfo.style }}>
      <div className={"wrapper"}>
        <Image src={slideInfo.image} placeholder={"placeholders/870.jpg"}/>
        <div className={"info-block"}>
          <TextBlock title={slideInfo.title}
                     subtitle={slideInfo.subtitle}
                     isMain={false} />
        </div>
      </div>
    </div>
  )
};

SimpleSlide.defaultProps = {
  className: "",
  index: 0,
};

SimpleSlide.propTypes = {
  slideInfo: PropTypes.object.isRequired,
  index: PropTypes.number,
  className: PropTypes.string
};

export default SimpleSlide;
