import React from "react";
import {Pannellum} from "pannellum-react";
import ParametersSlider from "./ParametersSlider";
import "./panoramaConfigurator.scss";
import Toggle from "../Toggle/Toggle";

class PanoramaConfigurator extends React.Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
        this.panoramaRef = React.createRef();
        this.initialData = props.data;
        const parameters = props.data["in"].parameters;
        this.state = {
            parameters,
            paramData: props.data,
            activeParameters: {},
            contentHeight: "auto",
            image: ""
        }
    }

    componentDidMount() {
        // this.onResize();
        // window.addEventListener("resize", this.onResize.bind(this));
    }

    generateImageURL(parameters) {
        const mainUrl = `${process.env.PUBLIC_URL + "/assets/images"}/360/${window.innerWidth < 768 ? "Mo" : "pc"}/in/${parameters["texture"]}/${parameters["colors"]}`;
        console.log(mainUrl + ".jpg", mainUrl + "_preview.jpg");
        return [mainUrl + ".jpg", mainUrl + "_preview.jpg"];
    }

    onResize() {
        this.setState({contentHeight: this.contentRef.current.offsetWidth * 0.38 + "px"})
    }

    onParameterChange(name, value, id, suitableList) {

        let {parameters, activeParameters} = this.state;
        activeParameters[name] = value;

        if (suitableList) {
            const initialData = Object.assign({}, this.initialData["in"].parameters);
            Object.keys(initialData)
                .map(param => {
                    if (param !== name) {
                        const initialSlides = initialData[param].slides;
                        initialSlides
                            .map(
                                slide => {
                                    if (!suitableList.includes(slide.id))
                                    {
                                        slide.hidden = true;
                                        slide.isActive = false;
                                    }
                                    else slide.hidden = false;
                                }
                            );

                        const activeElements = initialSlides.filter(element => element.isActive === true && !element.hidden);

                        if (activeElements.length === 0) {
                            initialSlides.find(element => !element.hidden).isActive = true;
                        }
                    }
                })
            parameters = initialData;
        }

        parameters[name]["slides"].map(parameter => {
            parameter.isActive = parameter.value === value;
            return parameter;
        });

        this.setState({parameters, activeParameters, image: this.generateImageURL(activeParameters)});
    }

    render() {
        const {parameters} = this.state;
        return (
            <div className={"panorama-configurator configurator"}>
                <div className={"main-content"} ref={this.contentRef}
                     // style={{"height": this.state.contentHeight}}
                >
                    <Pannellum width={"100%"}
                               height={"100%"}
                               ref={this.panoramaRef}
                               showControls={false}
                               minHfov={90}
                               hfov={105}
                               maxHfov={120}
                               autoLoad={true}
                               image={this.state.image[0]}
                               preview={this.state.image[1]}
                    />
                </div>
                <Toggle options={this.props.options}
                        activePosition={1}
                        callback={this.props.onToggle}/>
                <div className={"parameters"}>
                    {
                        Object.keys(parameters)
                            .map(key => <ParametersSlider slides={parameters[key].slides}
                                                          name={key}
                                                          title={parameters[key].title}
                                                          callback={this.onParameterChange.bind(this)}/>)
                    }
                </div>
            </div>
        )
    }
}

export default PanoramaConfigurator;