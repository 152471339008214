import React from 'react';
import Parser from "html-react-parser";

import Image from "../UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

import './featuresBanner.scss';

export const FeaturesBanner = ({src, title, label}) => {


  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <div className={"fs-banner"}>
            <div className={"cover"}>
              <div className={"dark-cover"}></div>
              <div className={"label"}>{context.getTranslate(label)}</div>
              <Image src={src}/></div>
            <div className={"title"}>{Parser(context.getTranslate(title))}</div>
          </div>
        );
      }}
    </LanguageContext.Consumer>
  )
};
