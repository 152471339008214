import React from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module'
import {LanguageContext} from "../../contexts/LanguageContext";
import Helmet from "react-helmet"
import ReactGA from 'react-ga';


import goodJSON from "../../content/good.json";
import confJSON from "../../content/Configurator360.json";
import exteriorJSON from "../../content/exterior.json";
import interiorJSON from "../../content/interior.json";
import featuresJSON from "../../content/features.json";
import convenienceJSON from "../../content/convenience.json";
import safeCare from '../../content/safeCare.json';
import galleyJSON from "../../content/gallery.json";
import learnMoreJSON from "../../content/learnMore.json";
import disclaimers from "../../content/disclaimers.json";
import {locations, fields, buttons, links, text} from "../../content/regions.json";

import Hero from "../../layouts/Hero/Hero";
import Good from "../../layouts/Good/Good";
import Interior from "../../layouts/Interior/Interior";
import Features from "../../layouts/Features/Features";
import Gallery from "../../layouts/Gallery/Gallery";
import Header from "../../layouts/Header/Header";
import LearnMore from "../../layouts/LearnMore/LearnMore";
import Footer from "../../layouts/Footer/Footer";
import Sprite from "../../components/UI/SvgSprite/Sprite";
import FormBasic from "../../layouts/FormBasic/FormBasic";
import PopupYoutube from "../../components/Popups/PopupYoutube";
import Disclaimers from "../../layouts/Disclaimers/Disclaimers";
import ConfiguratorSection from "../../layouts/Configurator/ConfiguratorSection";
import SmallSlider from "../../layouts/SmallSlider/SmallSlider";
import Image from "../../components/UI/Images/Image";
import {Play} from "../../layouts/Play/Play";
import {SafeCare} from "../../layouts/SafeCare/SafeCare";
import {MainFeatures} from "../../layouts/MainFeatures/MainFeatures";
import {TileContainer} from "../../layouts/tileContainer/TileContainer";
import PopupMenu from "../../components/Popups/PopupMenu/popupMenu";

const Main = ({match}) => {


  const tagManagerArgs = {
    gtmId: 'GTM-PQPZJZX'
  };

  const region = match.params.region;
  const lang = LanguageContext._currentValue.lang;

  // console.log(confJSON);

  React.useEffect(() => {
    // TagManager.initialize(tagManagerArgs);
    ReactGA.initialize('UA-184089575-1');
    ReactGA.pageview(region);
    // const country = ["Jeddah", "Dammam", "Riyadh"].includes(region) ? "Saudi Arabia" : region;
    // const noscript = document.createElement("noscript");
    // noscript.innerHTML=`<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gquo;cat=hyund0;u1=${window.location.href};u2=${lang};u3=${country};u4=${region};u5=Elantra;u6=none;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
    // window.gtag('event', 'conversion', {
    //     'allow_custom_scripts': true,
    //     'u1': window.location.href,
    //     'u2': lang,
    //     'u3': country,
    //     'u4': region,
    //     'u5': 'Elantra',
    //     'u6': 'none',
    //     'send_to': 'DC-3220295/gquo/hyund0+unique'
    // });
    // document.querySelector("body").appendChild(noscript);
  });

  const formFields = fields?.[region] || {
    "firstName": {
      value: "",
      placeholder: "inputs.firstName"
    },
    "lastName": {
      value: "",
      placeholder: "inputs.lastName",
    },
    "email": {
      value: "",
      placeholder: "inputs.email",
      type: "email",
    },
    "phone": {
      value: "",
      placeholder: "inputs.mobile",
      type: "tel",
    }
  };

  const onFileDownload = () => {
    // const country = ["Jeddah", "Dammam", "Riyadh"].includes(region) ? "Saudi Arabia" : region;
    // const noscript = document.createElement("noscript");
    // noscript.innerHTML = `<img src="https://ad.doubleclick.net/ddm/activity/src=3220295;type=gbro;cat=hyund0;u1=${window.location.href};u2=${lang};u3=${country};u4=${region};u5=Elantra;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?" width="1" height="1" alt=""/>`;
    // window.gtag('event', 'conversion', {
    //   'allow_custom_scripts': true,
    //   'u1': window.location.href,
    //   'u2': lang,
    //   'u3': country,
    //   'u4': region,
    //   'u5': 'SantaFe',
    //     'send_to': 'DC-3220295/gbro/hyund0+unique'
    // });
    // document.querySelector("body").appendChild(noscript);
  }

  if (locations?.[region]) {
    !!formFields["city"] ? Object.assign(formFields["city"], {options: locations[region]}) : formFields["city"] = {
      value: "",
      type: "select",
      placeholder: "inputs.location",
      defaultValue: "location",
      options: locations[region]
    };
  }

  const [heightScroll, setHeightScroll] = React.useState()

  return (
    <LanguageContext.Consumer>

      {context => {

        const dir = context.getTranslate("direction");
        return (
          <main className={`pp-page-elantra ${dir}`}
                dir={dir}>

            <Helmet>
              <meta property={"og:image"}
                    content={`${process.env.REACT_APP_URL}assets/images/opengraph/${region}/open_graph.jpg`}/>
            </Helmet>
            <PopupYoutube btnOpen={"js-open-youtube"}
                          youtubeId={"cPkxrqnoLQI"}/>
            <Sprite/>
            <Header dir={dir}/>
            <PopupMenu dir={dir} btnOpen={"js-open-menu"} />
            <Hero region={region}
                  useYoutube={false}
                  dir={dir}/>
            <FormBasic fields={formFields}
                       lang={lang}
                       region={region}
                       button={buttons?.[region] || ""}
                       dir={dir}/>
            <Play idSection={"wideWise"} bubble={""} youtubeId={context.getTranslate("video.play")}
                  title={"main_play.title"} subtitle={"main_play.subtitle"}/>
            <SafeCare id={"safeCare"} dir={dir}
                      title={safeCare.title}
                      subtitle={safeCare.subtitle}
                      data={safeCare.data}
                      useVideoButton={true}
                      useVideo={true}
            />
            {/*<Good title={goodJSON.title}
                              changeTitle={true}
                              className={"centered"}
                              slideType={"PictureSlide"}
                              sliderClassName={"slim"}
                              slideClassName={"white-counter"}
                              subtitle={goodJSON.subtitle}
                              slides={goodJSON.slides[region]}
                              dir={dir}/>*/}
            <ConfiguratorSection
              id={"configurator"}
              data={confJSON[region]}
              title={"configurator.title"}
              subtitle={text[region] ? text[region].configurator ? text[region].configurator : "configurator.subtitle" : "configurator.subtitle"}
              className={"centered"}
            />
            <Features id={"exterior"} title={exteriorJSON.title}
                      categories={exteriorJSON.categories}
                      data={exteriorJSON.data[region]}
                      dir={dir}/>
            {/*<Exterior title={exteriorJSON.title}*/}
            {/*          className={"centered"}*/}
            {/*          slides={exteriorJSON.slides[region]}*/}
            {/*          dir={dir}/>*/}
            <Interior id={"interior"} title={interiorJSON.title}
                      className={"centered blue"}
                      subtitle={interiorJSON.subtitle}
                      slides={interiorJSON.slides[region]}
                      dir={dir}/>
            <MainFeatures id={"mainFeatures"} dir={dir} title={featuresJSON.title} textAbove={featuresJSON.categories}
                          slides={featuresJSON.slides[region]} smallSlides={featuresJSON.smallSlider[region]}/>
            {/*  <Features title={featuresJSON.title}
                                  smallSlides={featuresJSON.smallSlider[region]}
                                  slides={featuresJSON.slides[region]}
                                  dir={dir}/>*/}

            {/*<SmallSlider title={convenienceJSON.title}
                         slides={convenienceJSON.slides[region]}
                         dir={dir}/>*/}

            <TileContainer id={"performance"} dir={dir} textAbove={featuresJSON.categories.performance}
                           smallSlides={featuresJSON.smallSlider[region].performance} />

            <TileContainer id={"comfort"} dir={dir} textAbove={featuresJSON.categories.comfort}
                           smallSlides={featuresJSON.smallSlider[region].comfort}
                           background={"grey"}
                           className={"white"}/>

            <Gallery id={"gallery"} title={galleyJSON.title}
                     mainSlides={galleyJSON.mainSlides[region]}
                     thumbSlides={galleyJSON.thumbSlides[region]}
                     dir={dir}/>


            <LearnMore title={learnMoreJSON.title[region]}
                       subtitle={learnMoreJSON.subtitle[region]}
                       link={links[region]}
                       region={region}
                       onFileDownload={onFileDownload.bind(this)}
                       btns={learnMoreJSON.btns}/>

            <Disclaimers disclaimers={disclaimers[region]}/>

            <Footer
              region={region}
            />
            <Image src={"/svg/top.svg"}
                   className={"top-button mobile"}
                   onClick={() => {
                     window.scrollTo({
                       top: 0,
                       behavior: "smooth"
                     })
                   }}/>
          </main>
        )
      }}
    </LanguageContext.Consumer>
  );

};

Main.propTypes = {};

export default Main;
