import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import PropTypes from 'prop-types';
import Image from "../../components/UI/Images/Image";
import ToggleLang from "../../components/ToggleLang/ToggleLang";
import "./Header.scss";
import {LanguageContext} from "../../contexts/LanguageContext";


const Header = ({ dir, opened }) => {

    const menu = [
        {
            text: "menu.wideWise",
            link: "wideWise",
        },
        {
            text: "menu.configurator",
            link: "configurator",
        },
        {
            text: "menu.exterior",
            link: "exterior",
        }
        ,
        {
            text: "menu.interior",
            link: "interior",
        }
        ,
        {
            text: "menu.mainFeatures",
            link: "mainFeatures",
        },
        {
            text: "menu.gallery",
            link: "gallery",
        }
    ]

/*    const scrollSite = (item) => {
        const zone = document.getElementById(item);
        zone.scrollIntoView({block: "center", behavior: "smooth"});
    }*/

    const [itemMenu, setItemMenu] = React.useState();

    return (
        <LanguageContext.Consumer>

            {context => {
                return (
                    <header dir={dir}>
                        <div className={"container"}>
                            <div className={"menu"}>
                                <Image src={"svg/logo.svg"}
                                       useLazy={false}
                                       alt={"Hyundai"}
                                       className={"logo"} />
                                <div className={"menu-list"}>
                                    {menu.map( item => <Link  activeClass="active"
                                                              to={item.link}
                                                              spy={true}
                                                              smooth={true}
                                                              duration={900}
                                                              offset={20}
                                      className={`menu-item `}>{context.getTranslate(item.text)}</Link>)}
             {/*                       {menu.map( item => <div onClick={() => scrollSite(item.link)}
                                                              className={`menu-item `}>{context.getTranslate(item.text)}</div>)}*/}
                                </div>
                            </div>

                            <Image src={"/svg/top.svg"} className={"top-button desktop"} onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth"
                                })
                            }}/>
                            <ToggleLang className={"desktop"}/>
                            <div className={`burger ${opened ? "js-close" : "js-open-menu"}`}>
                                <Image src={`/icons/${opened ? "close" : "burger"}.svg`}/>
                            </div>
                        </div>
                    </header>
                );
            }}
        </LanguageContext.Consumer>
    );

};

Header.propTypes = {};

export default Header;
