import React from 'react';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module'
import * as ReactGA from "react-ga";
import { regions } from "../../content/regions.json";

import Image from "../../components/UI/Images/Image";
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

import "./Regions.scss";
import Region from "../../components/Region/Region";

const Regions = () => {

  const tagManagerArgs = {
    gtmId: 'GTM-KBHZMKJ'
  };

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize('UA-179641996-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const temporaryRegs = [
    "Jeddah",
    "Riyadh",
    "Dammam",
    "UAE",
    "Bahrain"

  ]

  return (
    <main>
      <section className={"pp-section-regions"}>
        <div className={"box"}>
          <Image className={"logo"}
                 src={"svg/logo.svg"}
                 alt={"Hyundai"} />
          <TitleBlock title={"Please select your region"} />
          <ul className={"regions"}>
            {temporaryRegs.map(region => <Region key={region} region={region} />)}
          </ul>
        </div>
      </section>
    </main>
  );

};

Regions.propTypes = {};

export default Regions;
