import React from 'react';
import PropTypes from 'prop-types';
import "./Toggle.scss";
import {LanguageContext} from "../../contexts/LanguageContext";

const Toggle = ({ options, callback, activePosition }) => {

  const [active, setActive] = React.useState(options[activePosition || 0]["value"]);

  const toggle = value => {
    setActive(value);
    return callback(value);
  };

  return (
      <LanguageContext.Consumer>
        {context => {return (
    <div className={"toggle"} dir={context.getTranslate("direction")}>
      <ul>
        {options.map(option => {
          return (
            <li key={option.label} className={active === option.value ? "active": ""}
                onClick={() => toggle(option.value)}>
              {context.getTranslate(option.label)}
            </li>
          )
        })}
      </ul>
    </div>
  );  }}
      </LanguageContext.Consumer>
  )

};

Toggle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  callback: PropTypes.func.isRequired
};

export default Toggle;
