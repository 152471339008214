import React from 'react';
import PropTypes from 'prop-types';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from 'swiper';

import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";

import 'swiper/swiper.scss'
import "./Interior.scss";
import Image from "../../components/UI/Images/Image";
import {LanguageContext} from "../../contexts/LanguageContext";

const Interior = ({title, subtitle, slides, dir, className, id}) => {

  const slidesCount = slides.length <= 4 ? 4 : 6;

  return (
    <LanguageContext.Consumer>
      {(context) => {

        context.checkLang();

        return (
          <section id={id} className={`pp-section pp-section-interior ${className || ""} ${dir}`}>
            <div className={"container"}>
              <TitleBlock title={title}
                          subtitle={subtitle}
                          className={"white"}/>
              <Swiper slidesPerView={window.innerWidth < 1024 ? 1 : slidesCount}
                      pagination
                      spaceBetween={24}>

                {window.innerWidth > 1024
                  ? slides.map(slide => <div className={"interior-block"}>
                    <Image src={slide.image}/>
                    <div className={"title"}>{context.getTranslate(slide.title)}</div>
                    <div className={"subtitle"}>{context.getTranslate(slide.subtitle)}</div>
                  </div>)

                  : slides.map((slide, index) => <SwiperSlide>
                      <Image src={slide.image}/>
                      <div className={"counter"}>{index + 1} {context.getTranslate("other.of")} {slides.length}</div>
                      <div className={"title"}>{context.getTranslate(slide.title)}</div>
                      <div className={"subtitle"}>{context.getTranslate(slide.subtitle)}</div>
                    </SwiperSlide>
                  )}
              </Swiper>
              {/*  <Slider slides={slides}
                className={"visible"}
                dir={dir}
                config={{ slidesPerView: 2, spaceBetween: 40 }} />*/}
            </div>
          </section>
        );
      }}
    </LanguageContext.Consumer>
  )
};


Interior.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Interior;
