import React from 'react';
import './SafeCare.scss';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";
import Slider from "../../components/Slider/Slider";
import {LanguageContext} from "../../contexts/LanguageContext";
import PopupYoutube from "../../components/Popups/PopupYoutube";
import Image from "../../components/UI/Images/Image";
import Svg from "../../components/UI/SvgSprite/Svg";
import Button from "../../components/UI/Button/Button";
import PropTypes from "prop-types";
import Toggle from "../../components/Toggle/Toggle";
import {TileBlock} from "../../components/TileBlock/TileBlock";

export const SafeCare = ({title, subtitle, data, useVideoButton, useVideo, aboveText, simpleSlider, id, dir}) => {
  const [slide, setSlide] = React.useState(1);

  const [dynamicTitle, setTitle] = React.useState(data[0].title);
  const [dynamicSubtitle, setSubtitle] = React.useState(data[0].subtitle);

  const renderSlides = (slide, index, length, callback) => {
    return (
      <LanguageContext.Consumer>
        {context => {
          return (
            <div id={id} className={"swiper-slide"}>
              <div className={"slide_video"}>
                <Image className={` js-open-youtube-${index + 1}`} src={slide.image}/>
                {useVideoButton && <Button className={`js-open-youtube-${index + 1}`} svgName={"play"}/>}
              </div>
              <div className={"slide_text"}>
                <img className={"icon"} src={process.env.PUBLIC_URL + "/assets/images" + slide.icon}/>
                <div className={"title"}>{context.getTranslate(slide.title)}</div>
                <div className={"slide_subtitle"}>
                  {context.getTranslate(slide.text)}
                </div>
              </div>
            </div>
          );
        }}
      </LanguageContext.Consumer>)
  };

  return (
    <LanguageContext.Consumer>
      {context => {
        return (
          <div className={`pp-section safe-care ${dir}`}>
            <div className={"container"}>
              {useVideo && data.map((slide, index) => <PopupYoutube youtubeId={slide.videoId}
                                                                    btnOpen={`js-open-youtube-${index + 1}`}/>)}

              {window.innerWidth > 1024
                ? <div className={"content"}>
                  <div className={`content_top ${aboveText ? "above" : ""}`}>

                    {aboveText && <TitleBlock className={"blue"} title={aboveText}/>}
                    <div className={"top_wrapper"}>
                      <div className={"content_top_icon"}>

                        {data.map((icon, index) => <div onClick={() => setSlide(index + 1)} className={`item`}>
                            {icon.name
                              ? <Svg svgClass={`${slide === index + 1 ? "active" : ""}`} name={icon.name}/>
                              : <Image src={icon.icon}/>
                            }
                            <div>{context.getTranslate(icon.title)}</div>
                          </div>
                        )}
                      </div>

                      <div style={dir === "ltr"
                        ? {left: `${(slide - 1) * 100 / data.length}%`, width: `${100 / data.length}%`}
                        : {left: `-${(slide - 1) * 100 / data.length}%`, width: `${100 / data.length}%`}}
                           className={`bar`}>
                      </div>
                    </div>


                  </div>

                  {data.map((item, index) => <div
                    className={`content_bottom ${slide === index + 1 ? "active" : "hide"}`}>
                    <div className={"video"}>
                      {useVideoButton && <Button className={`js-open-youtube-${index + 1}`} svgName={"play"}/>}
                      <Image className={`${useVideo ? `js-open-youtube-${index + 1}` : ""}`} src={item.image}/>
                    </div>

                    <div className={"text"}>
                      <TitleBlock title={item.title}/>
                      <div className={"subtitle"}>
                        {context.getTranslate(item.text)}
                      </div>
                    </div>
                  </div>)
                  }
                </div>

                : simpleSlider
                  ? <TileBlock dir={dir} slides={data} title={aboveText} className={"white"}/>
                  : <Slider slides={data}
                          className={`visible ${"" || ""}`}
                          forceOnChange={true}
                          dir={dir}
                          useControls={true}
                          usePagination={true}
                          onChange={(e) => {
                            setTitle("data[e].title");
                            setSubtitle("data[e].subtitle");
                          }}
                          renderSlide={renderSlides}
                          config={{slidesPerView: 1, spaceBetween: 40}}/>}
            </div>
          </div>
        );
      }}
    </LanguageContext.Consumer>)
};

SafeCare.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  useVideoButton: PropTypes.bool,
  useVideo: PropTypes.bool
};