import React from 'react';
import PropTypes from 'prop-types';
import {LanguageContext} from "../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./disclaimers.scss";

const Disclaimers = ({disclaimers}) => {
    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <section className={"pp-section no-top pp-section-disclaimers"}>
                        <div className={"container"}>
                        {disclaimers.map(disclaimer => {
                            return <p>{Parser(context.getTranslate(disclaimer))}</p>
                        })}
                        </div>
                    </section>
                )
            }}
        </LanguageContext.Consumer>
    )
}

Disclaimers.propTypes = {
    disclaimers: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default Disclaimers;