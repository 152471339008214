import React from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from "../../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./TitleBlock.scss";
import Button from "../Button/Button";

const TitleBlock = ({ title, subtitle, isMain, className, useYoutube, dir, height }) => {

  return (
    <LanguageContext.Consumer>
      {context => {

        return (
          <div className={`title-block ${className}`} style={height ? {height:`${height}px`}: {}}>
            {isMain
              ? <h1 className={"title"}>{Parser(context.getTranslate(title))}</h1>
              : <h2 className={"title"}>{Parser(context.getTranslate(title))}</h2>
            }
            {subtitle
              ? <p className={"subtitle"}>{Parser(context.getTranslate(subtitle))}</p>
              : null}
            {useYoutube
              ? <Button className={"js-open-youtube"} svgName={"play"} text={"btns.watch"} />
              : null}
          </div>
        )

      }}
    </LanguageContext.Consumer>
  )

};

TitleBlock.defaultProps = {
  subtitle: "",
  isMain: false,
  className: "",
  useYoutube: false
};

TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isMain: PropTypes.bool,
  className: PropTypes.string,
  useYoutube: PropTypes.bool,
  dir: PropTypes.string
};

export default TitleBlock;
