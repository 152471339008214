import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Image from "../UI/Images/Image";
import "./Region.scss";

const Region = ({ region }) => {

  const regions = ["UAE", "Qatar", "Egypt", "Kuwait", "Bahrain"];

  const imageName = regions.includes(region) ? region : "sa";

  return (
    <Link to={`${process.env.REACT_APP_URL}${region}`} className={"region"}>
      <Image src={`regions/${imageName.toLowerCase()}.png`}
             alt={region} />
      <p>{region}</p>
    </Link>
  );

};

Region.propTypes = {};

export default Region;
