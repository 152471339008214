import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';

import DefaultSlide from "./Slides/DefaultSlide/DefaultSlide";
import Controls from "./Controls/Controls";
import Pagination from "./Pagination/Pagination";
import "./slider.scss";
import { LanguageContext } from "../../contexts/LanguageContext";

class Slider extends React.Component {

  constructor(props) {
    super(props);
    this.config = props.config || {};
    this.filterRef = React.createRef();
    this.sliderRef = React.createRef();
    this.useControls = props.useControls;
    this.usePagination = props.usePagination;

    this.state = {
      slides: props.slides,
      filtersSlides: props.filtersSlides,
      dir: props.dir
    };
  }

  componentDidMount() {
    this.slider = this.sliderRef.current;
    this.container = this.slider.querySelector(".swiper-container");
    const config = this.getConfig();
    this.mySwiper = new Swiper(this.container, config);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slides !== prevProps.slides) {
      this.setState({ slides: this.props.slides }, () => {
        this.mySwiper.update();
        this.mySwiper.slideTo(0);
      });
    }
    if (this.props.dir !== prevProps.dir) {
      this.setState({ dir: this.props.dir });
      setTimeout(() => {
        this.mySwiper.destroy(true);
        const config = this.getConfig();
        this.mySwiper = new Swiper(this.container, config);
      }, 10);
    }
  }

  getConfig() {

    let { nextEl, prevEl, useFilter, onChange, mobileConfig } = this.props;
    prevEl = `.${prevEl}`;
    nextEl = `.${nextEl}`;

    let config = {
      slidesPerView: 1,
      spaceBetween: 8,
      watchOverflow: true,
      observer: true,
      observeParents: true,
      preloadImages: this.props.preloadImages || true,
      mousewheel: {
        invert: false,
        forceToAxis: true,
      },
      keyboard: {
        enabled: false,
        onlyInViewport: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
        dynamicBullets: false,
      },
      breakpoints: {
        768: {}
      }
    };

    config = { ...config, ...mobileConfig };

    config["breakpoints"]["768"] = this.config;

    if (config["pagination"]?.type === "bullets") {
      config["pagination"]["bulletActiveClass"] = "active";
      config["pagination"]["dynamicMainBullets"] = 4;
      config["pagination"]["dynamicBullets"] = this.props.slides.length >= 6;
      config["pagination"]["modifierClass"] = this.props.slides.length >= 6 ? "dynamic " : "";
    }

    if (this.useControls) {
      config["breakpoints"]["768"]["navigation"] = {
        nextEl,
        prevEl,
        disabledClass: "disabled",
      };
    }

    if (!this.usePagination) {
      config["breakpoints"]["768"]["pagination"] = { el: "" };
    }

    if (config["breakpoints"]["768"]["pagination"]?.type === "bullets") {
      config["breakpoints"]["768"]["pagination"]["bulletActiveClass"] = "active";
      config["breakpoints"]["768"]["pagination"]["dynamicMainBullets"] = 4;
      config["breakpoints"]["768"]["pagination"]["dynamicBullets"] = this.props.slides.length >= 4;
      config["breakpoints"]["768"]["pagination"]["modifierClass"] = this.props.slides.length >= 4 ? "dynamic " : "";
    }

    if (this.props.forceOnChange) {
      config["on"] = {
        slideChange: () => {
          onChange(this.mySwiper.realIndex);
        }
      }
    }
    if (useFilter) {
      config["on"] = {
        slideChange: () => {
          onChange(this.mySwiper.realIndex);
          this.updateFilters(this.mySwiper.realIndex);
        }
      }
    }

    return config;

  }

  renderSlides(slide, index, renderSlide, length, callback) {
    return renderSlide
      ? renderSlide(slide, index, length, callback)
      : <DefaultSlide key={index} index={index} slideInfo={slide} length={length} callback={callback} />
  }

  filterCallback(index) {
    this.mySwiper.slideTo(index);
    this.updateFilters(index);
  }

  updateFilters(index) {
    this.mySwiper.slideTo(index);
    this.filterRef.current.mySwiper.slideTo(index);
  }

  slideTo(index){
    this.mySwiper.slideTo(index);
  }

  render() {

    const { className, renderSlide, controlsClass, nextEl, prevEl, useCustomControls, useFilter, renderFilter, callback, nextElSvg, prevElSvg, paginationClass } = this.props;
    const { filtersSlides } = this.state;

    const { slides, dir } = this.state;

    return (
      <>
        <div className={`slider ${className}`} ref={this.sliderRef} dir={dir}>
          <div className={"slider-content"}>
            <div className={"swiper-container"}>
              <div className={"swiper-wrapper"}>
                {slides.map((slide, index) => this.renderSlides(slide, index, renderSlide, slides.length, callback))}
              </div>
              {this.useControls && !useCustomControls
                ? <Controls className={controlsClass}
                            direction={dir}
                            prevEl={prevEl}
                            nextEl={nextEl} />
                : null}
              <Pagination className={`${paginationClass}  ${this.props.slides.length >= 4 ? "dynamic " : ""}`} direction={dir} />
            </div>
            {this.useControls && useCustomControls
              ? <Controls className={controlsClass}
                          prevEl={prevEl}
                          nextEl={nextEl}
                          direction={dir}
                          prevElSvg={prevElSvg}
                          nextElSvg={nextElSvg}
                          useWrapper={false} />
              : null}
          </div>
        </div>
        {useFilter
          ? renderFilter(filtersSlides, this.filterCallback.bind(this), this.filterRef)
          : null}
      </>
    )
  }

}

Slider.defaultProps = {
  className: "",
  useControls: true,
  usePagination: false,
  renderSlide: null,
  controlsClass: "",
  nextEl: "swiper-button-next",
  prevEl: "swiper-button-prev",
  useCustomControls: false,
  useFilter: false,
  renderFilter: null,
  filtersSlides: [],
  callback: null,
  prevElSvg: "arrow-reverse",
  nextElSvg: "arrow",
  paginationClass: "",
};

Slider.propTypes = {
  className: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSlide: PropTypes.func,
  useControls: PropTypes.bool,
  usePagination: PropTypes.bool,
  controlsClass: PropTypes.string,
  nextEl: PropTypes.string,
  prevEl: PropTypes.string,
  useCustomControls: PropTypes.bool,
  useFilter: PropTypes.bool,
  renderFilter: PropTypes.func,
  filtersSlides: PropTypes.func,
  callback: PropTypes.func,
  prevElSvg: PropTypes.string,
  nextElSvg: PropTypes.string,
  onChange: PropTypes.func,
  paginationClass: PropTypes.string,
};

export default Slider;
