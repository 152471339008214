import React from 'react';
import PropTypes from 'prop-types';
import {LanguageContext} from "../../../../contexts/LanguageContext";
import Image from "../../../UI/Images/Image";
import "./parameterSlide.scss";
import Svg from "../../../UI/SvgSprite/Svg";

const ParameterSlide = ({slideInfo, callback}) => {

    if (slideInfo.isActive) {
        // callback(slideInfo.value);
    }

    return (
        <LanguageContext.Consumer>
            {context => {
                return (
                    <div className={`swiper-slide parameter-slide ${slideInfo.isActive ? "active" : ""}`} onClick={()=>{callback(slideInfo.value, slideInfo.id, slideInfo.suitable || false)}}>
                        {slideInfo.isActive ? <Image src={"/svg/check.svg"} className={"check"}/> : null}
                        <Image useLazy={false} src={slideInfo.preview} alt={slideInfo.alt || slideInfo.value}/>
                    </div>
                )}}
        </LanguageContext.Consumer>
    )
}


ParameterSlide.propTypes = {
    slideInfo: PropTypes.object.isRequired,
    callback: PropTypes.func
}

export default ParameterSlide;