import React from "react";
import PropTypes from 'prop-types';
import { LanguageContext } from "../../contexts/LanguageContext";
import Parser from "html-react-parser";
import "./parametersSlider.scss";
import Slider from "../Slider/Slider";
import ParameterSlide from "../Slider/Slides/ParameterSlide/ParameterSlide";

class ParametersSlider extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.config = {
      spaceBetween: 24,
      breakpoints: {
        0: {
          slidesPerView: 4
        },
        768: {
          slidesPerView: "auto"
        }
      }
    };

    this.mobileConfig = {
      slidesPerView: "auto",
      spaceBetween: 16
    }

    this.state = {
      title: this.props.title,
      subtitle: "",
      value: "",
      parameters: this.getParametersList()
    };

  }

  componentDidMount() {
    this.getActiveParameter();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps){
      this.setState({parameters: this.getParametersList(), subtitle: this.returnSubtitle(this.props.slides)});
    }
  }

  getParametersList(){
    const list = [];
    this.props.slides.map(slide => {
      if (!slide.hidden) list.push(slide);
    })
    return list;
  }

  returnSubtitle(parameters){
    let subtitle = "";
    parameters.map(parameter =>{
      if (parameter.isActive && !parameter.hidden) {
        subtitle = parameter.name;
      }
    })
    return subtitle;
  }

  getActiveParameter() {
    this.state.parameters.map((parameter, index) => {
      if (parameter.isActive) {
        this.setState({ subtitle: parameter.name }, () => {
          this.props.callback(this.props.name, parameter.value, parameter.id, parameter.suitable);
          if (window.innerWidth < 768) this.sliderRef.current.slideTo(index);
        });
      }
    })
  }

  onParameterChange(value, name, id, suitable) {
    this.props.callback(this.props.name, value, name, id, suitable);
    this.getActiveParameter();
  }

  renderSlide(slide) {
    return <ParameterSlide slideInfo={slide} callback={this.onParameterChange.bind(this)} />
  }

  render() {

    return (
      <LanguageContext.Consumer>
        {context => {
          return (
            <div className={"parameters-slider"}>

              <div className={"info"}>
                 <span className={"title"}>
                   {Parser(context.getTranslate(this.state.title))}
                   </span>

                <span className={"subtitle"}>
                  {Parser(context.getTranslate(this.state.subtitle))}
                  </span>
              </div>

              <div className={"content"}>
                <Slider slides={this.state.parameters}
                        ref={this.sliderRef}
                        config={this.config}
                        mobileConfig={this.mobileConfig}
                        renderSlide={this.renderSlide.bind(this)}
                        useControls={true}
                        usePagination={false}
                        duoSlide={false} />
              </div>
            </div>
          )
        }}
      </LanguageContext.Consumer>
    )
  }

}

ParametersSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ParametersSlider;
