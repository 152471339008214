import React from 'react';
import PropTypes from 'prop-types';
import Svg from "../../UI/SvgSprite/Svg";
import "./controls.scss";

const Controls = ({ className, prevEl, nextEl, useWrapper, prevElSvg, nextElSvg, direction }) => {

  const renderArrows = (prevEl, nextEl, className = "") => {
    return (
      <>
        <div className={`control-arrow reversed ${prevEl} ${className}`}>
          <Svg name={prevElSvg} />
        </div>
        <div className={`control-arrow ${nextEl} ${className}`}>
          <Svg name={nextElSvg} />
        </div>
      </>
    )
  };

  if (useWrapper) {
    return (
      <div className={`controls ${className} ${direction || ""}`}>
        {renderArrows(prevEl, nextEl)}
      </div>
    );
  } else {
    return renderArrows(prevEl, nextEl, "single",);
  }
};

Controls.defaultProps = {
  className: "",
  useWrapper: true,
  prevElSvg: "arrow-reverse",
  nextElSvg: "arrow",
};

Controls.propTypes = {
  className: PropTypes.string,
  prevEl: PropTypes.string.isRequired,
  nextEl: PropTypes.string.isRequired,
  useWrapper: PropTypes.bool,
  prevElSvg: PropTypes.string,
  nextElSvg: PropTypes.string,
};

export default Controls;
