import React from 'react';
import PropTypes from 'prop-types';
import TitleBlock from "../../components/UI/TitleBlock/TitleBlock";

import "./mainFeatures.scss";
import {SafeCare} from "../SafeCare/SafeCare";
import {TileBlock} from "../../components/TileBlock/TileBlock";


export const MainFeatures = ({ title, slides, smallSlides, textAbove, dir, className, id}) => {

    return (
        <section id={id} className={`pp-section pp-section-mf ${className || ""}`}>
            <div className={"container"}>
                <TitleBlock title={title}
                            className={"center"} />
                <SafeCare dir={dir}
                          data={slides.safety}
                          simpleSlider={true}
                          aboveText={textAbove.safety}
                />

                <TileBlock dir={dir} slides={smallSlides.safety} className={"white height"}/>
            </div>
        </section>
    );

};

MainFeatures.propTypes = {
    title: PropTypes.string,
    slides: PropTypes.object,
    smallSlides: PropTypes.arrayOf(PropTypes.object),
};
