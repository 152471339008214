import React from "react";
import YouTube from 'react-youtube';
import Popup from "./Popup";
import Svg from "../UI/SvgSprite/Svg";

import "./popup.scss";

class PopupYoutube extends Popup {

  constructor(props) {
    super(props);
    this.fields = this.props.fields;
    this.player = {};
    this.youtubeConfig = {
      width: "100%",
      height: "100%"
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  beforeOpen() {
    try {
      this.player.playVideo();
    } catch (e) {}
  }

  beforeClose() {
    try {
      this.player.pauseVideo();
    } catch (e) {}
  }

  _onReady(event) {
    this.player = event.target;
    event.target.pauseVideo();
  }

  render() {
    return (
      <div className={"pp-popup pp-popup-youtube pp-popup-modal"} ref={this.ref}>
        <div className={"overlay"}></div>
        <div className={"close js-close"}>
          <Svg name={"close"} />
        </div>
        <div className={"box"}>
          <YouTube
            playerVars={{ "part": "snippet" }}
            videoId={this.props.youtubeId}
            opts={this.youtubeConfig}
            onReady={this._onReady.bind(this)}
            containerClassName={"youtube"} />
        </div>
      </div>
    )
  }

}

export default PopupYoutube;
