import React from 'react';
import PropTypes from 'prop-types';
import {LanguageContext} from "../../../contexts/LanguageContext"
import Parser from "html-react-parser";
import "./TextBlock.scss";

const TextBlock = ({ title, subtitle, isMain, className }) => {

  return (
    <LanguageContext.Consumer>
      {context => {

        return (
          <div className={`text-block ${className}`}>
            {isMain
              ? <h3 className={"title"}>{Parser(context.getTranslate(title))}</h3>
              : <h4 className={"title"}>{Parser(context.getTranslate(title))}</h4>
            }
            {subtitle ? <p className={"subtitle"}>{Parser(context.getTranslate(subtitle))}</p> : null}
          </div>
        );

      }}
    </LanguageContext.Consumer>
  )

};

TextBlock.defaultProps = {
  subtitle: "",
  isMain: false,
  className: "",
};

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isMain: PropTypes.bool,
  className: PropTypes.string,
};

export default TextBlock;
